(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

(function ($) {
  "use strict";

  var passiveSupported = false;

  try {
    var options = Object.defineProperty({}, 'passive', {
      get: function get() {
        passiveSupported = true;
      }
    });
    window.addEventListener('test', null, options);
  } catch (err) {}

  var DIRECTION = null;

  function direction() {
    if (DIRECTION === null) {
      DIRECTION = getComputedStyle(document.body).direction;
    }

    return DIRECTION;
  }

  function isRTL() {
    return direction() === 'rtl';
  }
  /*
  // initialize custom numbers
  */


  $(function () {
    $('.input-number').customNumber();
  });
  /*
  // product tabs
  */

  $(function () {
    $('.product-tabs').each(function (i, element) {
      var list = $('.product-tabs__list', element);
      list.on('click', '.product-tabs__item', function (event) {
        event.preventDefault();
        var tab = $(this);
        var pane = $('.product-tabs__pane' + $(this).attr('href'), element);

        if ($(element).is('.product-tabs--stuck')) {
          window.scrollTo(0, $(element).find('.product-tabs__content').offset().top - $(element).find('.product-tabs__list-body').outerHeight() + 2);
        }

        if (pane.length) {
          $('.product-tabs__item').removeClass('product-tabs__item--active');
          tab.addClass('product-tabs__item--active');
          $('.product-tabs__pane').removeClass('product-tabs__pane--active');
          pane.addClass('product-tabs__pane--active');
        }
      });
      var currentTab = $('.product-tabs__item--active', element);
      var firstTab = $('.product-tabs__item:first', element);

      if (currentTab.length) {
        currentTab.trigger('click');
      } else {
        firstTab.trigger('click');
      }

      if ($(element).is('.product-tabs--sticky')) {
        var calc = function calc() {
          stuckWhen = list.offset().top + list.outerHeight();
          fixedWhen = $(element).find('.product-tabs__content').offset().top - $(element).find('.product-tabs__list-body').outerHeight() + 2;
        };

        var onScroll = function onScroll() {
          if (stuckWhen === null || fixedWhen === null) {
            calc();
          }

          if (window.pageYOffset >= stuckWhen) {
            $(element).addClass('product-tabs--stuck');
          } else if (window.pageYOffset < fixedWhen) {
            $(element).removeClass('product-tabs--stuck');
            $(element).removeClass('product-tabs--header-stuck-hidden');
          }
        };

        var stuckWhen = null;
        var fixedWhen = null;
        window.addEventListener('scroll', onScroll, passiveSupported ? {
          passive: true
        } : false);
        $(document).on('stroyka.header.sticky.show', function () {
          $(element).addClass('product-tabs--header-stuck');
          $(element).removeClass('product-tabs--header-stuck-hidden');
        });
        $(document).on('stroyka.header.sticky.hide', function () {
          $(element).removeClass('product-tabs--header-stuck');

          if ($(element).is('.product-tabs--stuck')) {
            $(element).addClass('product-tabs--header-stuck-hidden');
          }
        });
        $(window).on('resize', function () {
          stuckWhen = null;
          fixedWhen = null;
        });
        onScroll();
      }
    });
  });
  /*
  // block slideshow
  */

  $(function () {
    $('.block-slideshow .owl-carousel').owlCarousel({
      items: 1,
      nav: false,
      dots: true,
      loop: true,
      rtl: isRTL()
    });
  });
  /*
  // block brands carousel
  */

  $(function () {
    $('.block-brands__slider .owl-carousel').owlCarousel({
      nav: false,
      dots: false,
      loop: true,
      rtl: isRTL(),
      responsive: {
        1200: {
          items: 6
        },
        992: {
          items: 5
        },
        768: {
          items: 4
        },
        576: {
          items: 3
        },
        0: {
          items: 2
        }
      }
    });
  });
  /*
  // block posts carousel
  */

  $(function () {
    $('.block-posts').each(function () {
      var layout = $(this).data('layout');
      var options = {
        margin: 30,
        nav: false,
        dots: false,
        loop: true,
        rtl: isRTL()
      };
      var layoutOptions = {
        'grid-3': {
          responsive: {
            992: {
              items: 3
            },
            768: {
              items: 2
            }
          }
        },
        'grid-4': {
          responsive: {
            1200: {
              items: 4,
              margin: 20
            },
            992: {
              items: 3,
              margin: 24
            },
            768: {
              items: 2,
              margin: 20
            },
            460: {
              items: 2,
              margin: 20
            }
          }
        },
        'list': {
          responsive: {
            992: {
              items: 2
            },
            0: {
              items: 1
            }
          }
        }
      };
      var owl = $('.block-posts__slider .owl-carousel');
      var owlOptions = $.extend({}, options, layoutOptions[layout]);

      if (/^grid-/.test(layout)) {
        var mobileResponsiveOptions = {};

        if (parseFloat($(this).data('mobile-columns')) === 2) {
          mobileResponsiveOptions = {
            460: {
              items: 2,
              margin: 20
            },
            400: {
              items: 2,
              margin: 16
            },
            320: {
              items: 2,
              margin: 12
            }
          };
        } else {
          mobileResponsiveOptions = {
            0: {
              items: 1
            }
          };
        }

        owlOptions.responsive = $.extend({}, owlOptions.responsive, mobileResponsiveOptions);
      }

      owl.owlCarousel(owlOptions);
      $(this).find('.block-header__arrow--left').on('click', function () {
        owl.trigger('prev.owl.carousel', [500]);
      });
      $(this).find('.block-header__arrow--right').on('click', function () {
        owl.trigger('next.owl.carousel', [500]);
      });
    });
  });
  /*
  // teammates
  */

  $(function () {
    $('.teammates .owl-carousel').owlCarousel({
      nav: false,
      dots: true,
      rtl: isRTL(),
      responsive: {
        768: {
          items: 3,
          margin: 32
        },
        380: {
          items: 2,
          margin: 24
        },
        0: {
          items: 1
        }
      }
    });
  });
  /*
  // quickview
  */

  var quickview = {
    cancelPreviousModal: function cancelPreviousModal() {},
    clickHandler: function clickHandler() {
      var modal = $('#quickview-modal');
      var button = $(this);
      var doubleClick = button.is('.product-card__quickview--preload');
      quickview.cancelPreviousModal();

      if (doubleClick) {
        return;
      }

      button.addClass('product-card__quickview--preload');
      var xhr = null; // timeout ONLY_FOR_DEMO!

      var timeout = setTimeout(function () {
        xhr = $.ajax({
          url: 'http://ocshop',
          success: function success(data) {
            quickview.cancelPreviousModal = function () {};

            button.removeClass('product-card__quickview--preload');
            modal.find('.modal-content').html(data);
            modal.find('.quickview__close').on('click', function () {
              modal.modal('hide');
            });
            modal.modal('show');
          }
        });
      }, 1000);

      quickview.cancelPreviousModal = function () {
        button.removeClass('product-card__quickview--preload');

        if (xhr) {
          xhr.abort();
        } // timeout ONLY_FOR_DEMO!


        clearTimeout(timeout);
      };
    }
  };
  $(function () {
    var modal = $('#quickview-modal');
    modal.on('shown.bs.modal', function () {
      modal.find('.product').each(function () {
        var gallery = $(this).find('.product-gallery');

        if (gallery.length > 0) {
          initProductGallery(gallery[0], $(this).data('layout'));
        }
      });
      $('.input-number', modal).customNumber();
    });
    $('.product-card__quickview').on('click', function () {
      quickview.clickHandler.apply(this, arguments);
    });
  });
  /*
  // products carousel
  */

  $(function () {
    $('.block-products-carousel').each(function () {
      var layout = $(this).data('layout');
      var options = {
        items: 4,
        margin: 14,
        nav: false,
        dots: false,
        loop: true,
        stagePadding: 1,
        rtl: isRTL()
      };
      var layoutOptions = {
        'grid-4': {
          responsive: {
            1200: {
              items: 4,
              margin: 14
            },
            992: {
              items: 4,
              margin: 10
            },
            768: {
              items: 3,
              margin: 10
            }
          }
        },
        'grid-4-sm': {
          responsive: {
            1200: {
              items: 4,
              margin: 14
            },
            992: {
              items: 3,
              margin: 10
            },
            768: {
              items: 3,
              margin: 10
            }
          }
        },
        'grid-5': {
          responsive: {
            1200: {
              items: 5,
              margin: 12
            },
            992: {
              items: 4,
              margin: 10
            },
            768: {
              items: 3,
              margin: 10
            }
          }
        },
        'horizontal': {
          items: 3,
          responsive: {
            1200: {
              items: 3,
              margin: 14
            },
            992: {
              items: 3,
              margin: 10
            },
            768: {
              items: 2,
              margin: 10
            },
            576: {
              items: 1
            },
            475: {
              items: 1
            },
            0: {
              items: 1
            }
          }
        }
      };
      var owl = $('.owl-carousel', this);

      var _cancelPreviousTabChange = function cancelPreviousTabChange() {};

      var owlOptions = $.extend({}, options, layoutOptions[layout]);

      if (/^grid-/.test(layout)) {
        var mobileResponsiveOptions;

        if (parseFloat($(this).data('mobile-grid-columns')) === 2) {
          mobileResponsiveOptions = {
            420: {
              items: 2,
              margin: 10
            },
            320: {
              items: 2,
              margin: 0
            },
            0: {
              items: 1
            }
          };
        } else {
          mobileResponsiveOptions = {
            475: {
              items: 2,
              margin: 10
            },
            0: {
              items: 1
            }
          };
        }

        owlOptions.responsive = $.extend({}, owlOptions.responsive, mobileResponsiveOptions);
      }

      owl.owlCarousel(owlOptions);
      $(this).find('.block-header__group').on('click', function (event) {
        var block = $(this).closest('.block-products-carousel');
        event.preventDefault();

        if ($(this).is('.block-header__group--active')) {
          return;
        }

        _cancelPreviousTabChange();

        block.addClass('block-products-carousel--loading');
        $(this).closest('.block-header__groups-list').find('.block-header__group--active').removeClass('block-header__group--active');
        $(this).addClass('block-header__group--active'); // timeout ONLY_FOR_DEMO! you can replace it with an ajax request

        var timer;
        timer = setTimeout(function () {
          var items = block.find('.owl-carousel .owl-item:not(".cloned") .block-products-carousel__column');
          /*** this is ONLY_FOR_DEMO! / start */

          /**/

          var itemsArray = items.get();
          /**/

          var newItemsArray = [];
          /**/

          /**/

          while (itemsArray.length > 0) {
            /**/
            var randomIndex = Math.floor(Math.random() * itemsArray.length);
            /**/

            var randomItem = itemsArray.splice(randomIndex, 1)[0];
            /**/

            /**/

            newItemsArray.push(randomItem);
            /**/
          }
          /**/


          items = $(newItemsArray);
          /*** this is ONLY_FOR_DEMO! / end */

          block.find('.owl-carousel').trigger('replace.owl.carousel', [items]).trigger('refresh.owl.carousel').trigger('to.owl.carousel', [0, 0]);
          $('.product-card__quickview', block).on('click', function () {
            quickview.clickHandler.apply(this, arguments);
          });
          block.removeClass('block-products-carousel--loading');
        }, 1000);

        _cancelPreviousTabChange = function cancelPreviousTabChange() {
          // timeout ONLY_FOR_DEMO!
          clearTimeout(timer);

          _cancelPreviousTabChange = function cancelPreviousTabChange() {};
        };
      });
      $(this).find('.block-header__arrow--left').on('click', function () {
        owl.trigger('prev.owl.carousel', [500]);
      });
      $(this).find('.block-header__arrow--right').on('click', function () {
        owl.trigger('next.owl.carousel', [500]);
      });
    });
  });
  /*
  // product gallery
  */

  var initProductGallery = function initProductGallery(element, layout) {
    layout = layout !== undefined ? layout : 'standard';
    var options = {
      dots: false,
      margin: 10,
      rtl: isRTL()
    };
    var layoutOptions = {
      standard: {
        responsive: {
          1200: {
            items: 5
          },
          992: {
            items: 4
          },
          768: {
            items: 3
          },
          480: {
            items: 5
          },
          380: {
            items: 4
          },
          0: {
            items: 3
          }
        }
      },
      sidebar: {
        responsive: {
          768: {
            items: 4
          },
          480: {
            items: 5
          },
          380: {
            items: 4
          },
          0: {
            items: 3
          }
        }
      },
      columnar: {
        responsive: {
          768: {
            items: 4
          },
          480: {
            items: 5
          },
          380: {
            items: 4
          },
          0: {
            items: 3
          }
        }
      },
      quickview: {
        responsive: {
          1200: {
            items: 5
          },
          768: {
            items: 4
          },
          480: {
            items: 5
          },
          380: {
            items: 4
          },
          0: {
            items: 3
          }
        }
      }
    };
    var gallery = $(element);
    var image = gallery.find('.product-gallery__featured .owl-carousel');
    var carousel = gallery.find('.product-gallery__carousel .owl-carousel');
    image.owlCarousel({
      items: 1,
      dots: false,
      rtl: isRTL()
    }).on('changed.owl.carousel', syncPosition);
    carousel.on('initialized.owl.carousel', function () {
      carousel.find('.product-gallery__carousel-item').eq(0).addClass('product-gallery__carousel-item--active');
    }).owlCarousel($.extend({}, options, layoutOptions[layout]));
    carousel.on('click', '.owl-item', function (e) {
      e.preventDefault();
      image.data('owl.carousel').to($(this).index(), 300, true);
    });
    gallery.find('.product-gallery__zoom').on('click', function () {
      openPhotoSwipe(image.find('.owl-item.active').index());
    });
    image.on('click', '.owl-item a', function (event) {
      event.preventDefault();
      openPhotoSwipe($(this).closest('.owl-item').index());
    });

    function getIndexDependOnDir(index) {
      // we need to invert index id direction === 'rtl' because photoswipe do not support rtl
      if (isRTL()) {
        return image.find('.owl-item img').length - 1 - index;
      }

      return index;
    }

    function openPhotoSwipe(index) {
      var photoSwipeImages = image.find('.owl-item a').toArray().map(function (element) {
        var img = $(element).find('img')[0];
        var width = $(element).data('width') || img.naturalWidth;
        var height = $(element).data('height') || img.naturalHeight;
        return {
          src: element.href,
          msrc: element.href,
          w: width,
          h: height
        };
      });

      if (isRTL()) {
        photoSwipeImages.reverse();
      }

      var photoSwipeOptions = {
        getThumbBoundsFn: function getThumbBoundsFn(index) {
          var imageElements = image.find('.owl-item img').toArray();
          var dirDependentIndex = getIndexDependOnDir(index);

          if (!imageElements[dirDependentIndex]) {
            return null;
          }

          var imageElement = imageElements[dirDependentIndex];
          var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
          var rect = imageElement.getBoundingClientRect();
          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          };
        },
        index: getIndexDependOnDir(index),
        bgOpacity: .9,
        history: false
      };
      var photoSwipeGallery = new PhotoSwipe($('.pswp')[0], PhotoSwipeUI_Default, photoSwipeImages, photoSwipeOptions);
      photoSwipeGallery.listen('beforeChange', function () {
        image.data('owl.carousel').to(getIndexDependOnDir(photoSwipeGallery.getCurrentIndex()), 0, true);
      });
      photoSwipeGallery.init();
    }

    function syncPosition(el) {
      var current = el.item.index;
      carousel.find('.product-gallery__carousel-item').removeClass('product-gallery__carousel-item--active').eq(current).addClass('product-gallery__carousel-item--active');
      var onscreen = carousel.find('.owl-item.active').length - 1;
      var start = carousel.find('.owl-item.active').first().index();
      var end = carousel.find('.owl-item.active').last().index();

      if (current > end) {
        carousel.data('owl.carousel').to(current, 100, true);
      }

      if (current < start) {
        carousel.data('owl.carousel').to(current - onscreen, 100, true);
      }
    }
  };

  $(function () {
    $('.product').each(function () {
      var gallery = $(this).find('.product-gallery');

      if (gallery.length > 0) {
        initProductGallery(gallery[0], $(this).data('layout'));
      }
    });
  });
  /*
  // Checkout payment methods
  */

  $(function () {
    $('[name="checkout_payment_method"]').on('change', function () {
      var currentItem = $(this).closest('.payment-methods__item');
      $(this).closest('.payment-methods__list').find('.payment-methods__item').each(function (i, element) {
        var links = $(element);
        var linksContent = links.find('.payment-methods__item-container');

        if (element !== currentItem[0]) {
          var startHeight = linksContent.height();
          linksContent.css('height', startHeight + 'px');
          links.removeClass('payment-methods__item--active');
          linksContent.height(); // force reflow

          linksContent.css('height', '');
        } else {
          var _startHeight = linksContent.height();

          links.addClass('payment-methods__item--active');
          var endHeight = linksContent.height();
          linksContent.css('height', _startHeight + 'px');
          linksContent.height(); // force reflow

          linksContent.css('height', endHeight + 'px');
        }
      });
    });
    $('.payment-methods__item-container').on('transitionend', function (event) {
      if (event.originalEvent.propertyName === 'height') {
        $(this).css('height', '');
      }
    });
  });
  /*
  // collapse
  */

  $(function () {
    $('[data-collapse]').each(function (i, element) {
      var collapse = element;
      $('[data-collapse-trigger]', collapse).on('click', function () {
        var openedClass = $(this).closest('[data-collapse-opened-class]').data('collapse-opened-class');
        var item = $(this).closest('[data-collapse-item]');
        var content = item.children('[data-collapse-content]');
        var itemParents = item.parents();
        itemParents.slice(0, itemParents.index(collapse) + 1).filter('[data-collapse-item]').css('height', '');

        if (item.is('.' + openedClass)) {
          var startHeight = content.height();
          content.css('height', startHeight + 'px');
          item.removeClass(openedClass);
          content.height(); // force reflow

          content.css('height', '');
        } else {
          var _startHeight2 = content.height();

          item.addClass(openedClass);
          var endHeight = content.height();
          content.css('height', _startHeight2 + 'px');
          content.height(); // force reflow

          content.css('height', endHeight + 'px');
        }
      });
      $('[data-collapse-content]', collapse).on('transitionend', function (event) {
        if (event.originalEvent.propertyName === 'height') {
          $(this).css('height', '');
        }
      });
    });
  });
  /*
  // price filter
  */
  // $(function () {
  //     $('.filter-price').each(function (i, element) {
  //         const min = $(element).data('min');
  //         const max = $(element).data('max');
  //         const from = $(element).data('from');
  //         const to = $(element).data('to');
  //         const slider = element.querySelector('.filter-price__slider');
  //
  //         noUiSlider.create(slider, {
  //             start: [from, to],
  //             connect: true,
  //             direction: isRTL() ? 'rtl' : 'ltr',
  //             range: {
  //                 'min': min,
  //                 'max': max
  //             }
  //         });
  //
  //         const titleValues = [
  //             $(element).find('.filter-price__min-value')[0],
  //             $(element).find('.filter-price__max-value')[0]
  //         ];
  //
  //         slider.noUiSlider.on('update', function (values, handle) {
  //             titleValues[handle].innerHTML = values[handle];
  //         });
  //     });
  // });

  /*
  // mobilemenu
  */

  $(function () {
    var body = $('body');
    var mobilemenu = $('.mobilemenu');

    if (mobilemenu.length) {
      var open = function open() {
        var bodyWidth = body.width();
        body.css('overflow', 'hidden');
        body.css('paddingRight', body.width() - bodyWidth + 'px');
        mobilemenu.addClass('mobilemenu--open');
      };

      var close = function close() {
        body.css('overflow', '');
        body.css('paddingRight', '');
        mobilemenu.removeClass('mobilemenu--open');
      };

      $('.mobile-header__menu-button').on('click', function () {
        open();
      });
      $('.mobilemenu__backdrop, .mobilemenu__close').on('click', function () {
        close();
      });
    }
  });
  /*
  // tooltips
  */

  $(function () {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover'
    });
  });
  /*
  // layout switcher
  */

  $(function () {
    $('.layout-switcher__button').on('click', function () {
      var layoutSwitcher = $(this).closest('.layout-switcher');
      var productsView = $(this).closest('.products-view');
      var productsList = productsView.find('.products-list');
      layoutSwitcher.find('.layout-switcher__button').removeClass('layout-switcher__button--active');
      $(this).addClass('layout-switcher__button--active');
      productsList.attr('data-layout', $(this).attr('data-layout'));
      productsList.attr('data-with-features', $(this).attr('data-with-features'));
    });
  });
  /*
  // offcanvas filters
  */

  $(function () {
    var body = $('body');
    var blockSidebar = $('.block-sidebar');
    var mobileMedia = matchMedia('(max-width: 991px)');

    if (blockSidebar.length) {
      var open = function open() {
        if (blockSidebar.is('.block-sidebar--offcanvas--mobile') && !mobileMedia.matches) {
          return;
        }

        var bodyWidth = body.width();
        body.css('overflow', 'hidden');
        body.css('paddingRight', body.width() - bodyWidth + 'px');
        blockSidebar.addClass('block-sidebar--open');
      };

      var close = function close() {
        body.css('overflow', '');
        body.css('paddingRight', '');
        blockSidebar.removeClass('block-sidebar--open');
      };

      var onChangeMedia = function onChangeMedia() {
        if (blockSidebar.is('.block-sidebar--open.block-sidebar--offcanvas--mobile') && !mobileMedia.matches) {
          close();
        }
      };

      $('.filters-button').on('click', function () {
        open();
      });
      $('.block-sidebar__backdrop, .block-sidebar__close').on('click', function () {
        close();
      });

      if (mobileMedia.addEventListener) {
        mobileMedia.addEventListener('change', onChangeMedia);
      } else {
        mobileMedia.addListener(onChangeMedia);
      }
    }
  });
  /*
  // .block-finder
  */

  $(function () {
    $('.block-finder__select').on('change', function () {
      var item = $(this).closest('.block-finder__form-item');

      if ($(this).val() !== 'none') {
        item.find('~ .block-finder__form-item:eq(0) .block-finder__select').prop('disabled', false).val('none');
        item.find('~ .block-finder__form-item:gt(0) .block-finder__select').prop('disabled', true).val('none');
      } else {
        item.find('~ .block-finder__form-item .block-finder__select').prop('disabled', true).val('none');
      }

      item.find('~ .block-finder__form-item .block-finder__select').trigger('change.select2');
    });
  });
  /*
  // select2
  */

  $(function () {
    $('.form-control-select2, .block-finder__select').select2({
      width: ''
    });
  });
  /*
  // totop
  */

  $(function () {
    var show = false;
    $('.totop__button').on('click', function () {
      $('html, body').animate({
        scrollTop: 0
      }, '300');
    });
    var fixedPositionStart = 300;
    window.addEventListener('scroll', function () {
      if (window.pageYOffset >= fixedPositionStart) {
        if (!show) {
          show = true;
          $('.totop').addClass('totop--show');
        }
      } else {
        if (show) {
          show = false;
          $('.totop').removeClass('totop--show');
        }
      }
    }, passiveSupported ? {
      passive: true
    } : false);
  });
})(jQuery);

},{}]},{},[1]);
